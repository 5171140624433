import axios from "axios";
import URL from "./url";

const instance = axios.create({
  baseURL: URL,
  headers: {
    "content-type": "application/json",
    Accept: "/",
  },
});

const instance2 = axios.create({
  baseURL: URL,
  headers: { "Content-Type": "multipart/form-data" },
});

const API = {
  getData: async function (link, data = {}) {
    return new Promise((resolve, reject) => {
      instance({
        method: "GET",
        url: link,
        data: data,
        timeout: 10000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          if (error.response === undefined) {
            resolve({ status: "error", result: error.message });
          } else {
            if (error.response.status === 401) {
              localStorage.removeItem("data");
              localStorage.removeItem("token");
              window.location.reload(true);
            }
            resolve({ status: "error", result: error.code });
          }
        });
    });
  },
  postData: async function (link, data = {}) {
    return new Promise((resolve, reject) => {
      instance({
        method: "POST",
        url: link,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          resolve({
            status: error.response.data.status,
            result: error.response.data.result,
          });
        });
    });
  },

  postFile: async function (link, data = {}) {
    return new Promise((resolve, reject) => {
      instance2({
        method: "POST",
        url: link,
        data: data,
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          let message;
          if (error.response === undefined) {
            message = error.message;
          } else {
            message = error.response.data.result;
          }
          resolve({
            status: "error",
            result: message,
          });
        });
    });
  },
  putData: async function (link, data = {}) {
    return new Promise((resolve, reject) => {
      instance({
        method: "PUT",
        url: link,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          resolve({
            status: error.response.data.status,
            result: error.response.data.result,
          });
        });
    });
  },
  deleteData: async function (link, data = {}) {
    return new Promise((resolve, reject) => {
      instance({
        method: "DELETE",
        url: link,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          resolve({
            status: error.response.data.status,
            result: error.response.data.result,
          });
        });
    });
  },
};
export default API;
