// let URL = "http://143.42.69.7:8086/api";
let URL = "https://api.btservice.mn/api";
if (process.env.NODE_ENV === "development") {
  // URL = "http://172.16.21.20:37303/api/";
  // URL = "http://143.42.69.7:8086/api";
  let URL = "https://api.btservice.mn/api";
  // URL = process.env.BACKEND_URL;
}

export default URL;
