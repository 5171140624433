import {
  Routes,
  Route,
  useLocation,
  Navigate,
  redirect,
  useHistory,
  useNavigate,
} from "react-router-dom";
import Layout from "../Layout";
import HomePage from "../HomePage";
import Page404 from "../404";
import ProductDetail from "../ProductDetail";
import CategoryFilter from "../CategoryFilter";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/category/:id" element={<CategoryFilter />} />
        {/* <Route index element={<Product />} />
          <Route path="/product" element={<Product />} />  */}

        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
}

export default App;
