import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import Image from "next/image";
import API from "../../../utils/axios";
import URL from "../../../utils/url";
import noImage from "../../../image/noImage.jpg";

export default function AboutSection() {
  //   const { ref } = useSectionInView("About");

  const [data, setData] = useState({});
  const [image, setImage] = useState([]);

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      let pages = await get("/setting/page?type=about");
      if (pages.status === "success") {
        let imageArray = JSON.parse(pages.result[0].image_path);
        setImage(imageArray.length > 0 ? imageArray : []);
        setData(pages.result);
      }
    };
    getData();
  }, []);

  return (
    <motion.section
      className="w-full mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-28"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.175 }}
      id="about"
    >
      <div className="w-full grid place-items-center">
        <p className="text-[40px] capitalize font-bold py-8">
          {data && data.length > 0 ? data[0].title : ""}
        </p>
        {image && image.length > 0 ? (
          <img
            width={500}
            height={300}
            src={`${URL.substring(0, URL.length - 4)}/${image[0].url}`}
          />
        ) : (
          <img width={500} height={300} src={noImage} />
        )}
        <p className="mt-10">
          {data && data.length > 0 ? data[0].content : ""}
        </p>
      </div>
    </motion.section>
  );
}
