import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Loading, Spacer } from "@nextui-org/react";

import API from "../../utils/axios";
import URL from "../../utils/url";
import noImage from "../../image/noImage.jpg";
import Page404 from "../404";
const parse = require("html-react-parser");

export default function ProductDetail() {
  const { id } = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState("");
  const [content, setContent] = useState([]);

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let product = await get(`/product/list/${id}`);
      let images = await get("/product/image");
      if (product.status === "success" && images.status === "success") {
        if (product.result.length > 0) {
          let findProduct = product.result.filter(
            (item) => item.id === parseInt(id)
          );
          if (findProduct.length > 0) {
            if (images.result.length > 0) {
              let findImage = images.result.filter(
                (item) => item.product_id === findProduct[0].id
              );
              if (findImage.length > 0) {
                setThumbnail(findImage[0].image_path);
                findProduct[0].images = findImage;
              }
            }

            await getContent(id);

            setData(findProduct[0]);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    getData();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getContent = async (getId) => {
    let productContent = await get(`/product/content/${getId}`);
    if (productContent.status === "success") {
      if (productContent.result !== null) {
        if (productContent.result.content !== null) {
          setContent(parse(productContent.result.content));
        }
      }
    }
  };

  return (
    <div>
      {data && data ? (
        <section className="py-12 sm:py-16">
          <div className="container mx-auto px-4">
            <nav className="flex">
              <ol role="list" className="flex items-center">
                <li className="text-left">
                  <div className="-m-1">
                    <Link
                      to="/"
                      className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      Нүүр
                    </Link>
                    {/* <a
                      href="#"
                      className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      Нүүр
                    </a> */}
                  </div>
                </li>

                <li className="text-left">
                  <div className="flex items-center">
                    <span className="mx-2 text-gray-400">/</span>
                    <div className="-m-1">
                      <Link
                        className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                        to={`/category/${
                          data && data.category_id !== null
                            ? data.category_id
                            : ""
                        }`}
                      >
                        Ангилал
                      </Link>
                    </div>
                  </div>
                </li>

                <li className="text-left">
                  <div className="flex items-center">
                    <span className="mx-2 text-gray-400">/</span>
                    <div className="-m-1">
                      <Link
                        href="#"
                        className="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                        aria-current="page"
                      >
                        {data && data.name !== null ? data.name : ""}
                      </Link>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>

            <div className="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
              <div className="lg:col-span-4 lg:row-end-1">
                <div className="lg:flex lg:items-start">
                  <div className="lg:order-2 lg:ml-5">
                    <div className="max-w-xl overflow-hidden rounded-lg">
                      {thumbnail && thumbnail !== null ? (
                        <img
                          className="h-full w-full max-w-full object-cover"
                          src={`${URL.substring(
                            0,
                            URL.length - 4
                          )}/${thumbnail}`}
                        />
                      ) : (
                        <img
                          className="h-full w-full max-w-full object-cover"
                          src={noImage}
                        />
                      )}
                    </div>
                  </div>

                  <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                    <div className="flex flex-row items-start lg:flex-col">
                      {data.images && data.images.length > 0
                        ? data.images.map((item, index) => (
                            <button
                              key={index}
                              type="button"
                              className="flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg border-2 border-transparent text-center"
                              onClick={() => {
                                setThumbnail(item.image_path);
                              }}
                            >
                              <img
                                className="h-full w-full object-cover"
                                src={`${URL.substring(0, URL.length - 4)}/${
                                  item.image_path
                                }`}
                              />
                            </button>
                          ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-8 lg:row-span-2 lg:row-end-2">
                <h1 className="sm: text-2xl font-bold text-gray-900 sm:text-3xl">
                  {data && data.name !== null ? data.name : ""}
                </h1>

                {/* <div className="mt-5 flex items-center">
                  <div className="flex items-center">
                    <svg
                      className="block h-4 w-4 align-middle text-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        className=""
                      ></path>
                    </svg>
                    <svg
                      className="block h-4 w-4 align-middle text-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        className=""
                      ></path>
                    </svg>
                    <svg
                      className="block h-4 w-4 align-middle text-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        className=""
                      ></path>
                    </svg>
                    <svg
                      className="block h-4 w-4 align-middle text-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        className=""
                      ></path>
                    </svg>
                    <svg
                      className="block h-4 w-4 align-middle text-yellow-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        className=""
                      ></path>
                    </svg>
                  </div>
                  <p className="ml-2 text-sm font-medium text-gray-500">
                    1,209 Reviews
                  </p>
                </div> */}

                <h2 className="mt-8 text-base text-gray-900">
                  {data && data.description !== null ? data.description : ""}
                </h2>
                {/* <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                  <label className="">
                    <input
                      type="radio"
                      name="type"
                      value="Powder"
                      className="peer sr-only"
                      checked
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      Powder
                    </p>
                  </label>
                  <label className="">
                    <input
                      type="radio"
                      name="type"
                      value="Whole Bean"
                      className="peer sr-only"
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      Whole Bean
                    </p>
                  </label>
                  <label className="">
                    <input
                      type="radio"
                      name="type"
                      value="Groud"
                      className="peer sr-only"
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      Groud
                    </p>
                  </label>
                </div>

                <h2 className="mt-8 text-base text-gray-900">
                  Choose subscription
                </h2>
                <div className="mt-3 flex select-none flex-wrap items-center gap-1">
                  <label className="">
                    <input
                      type="radio"
                      name="subscription"
                      value="4 Months"
                      className="peer sr-only"
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      4 Months
                    </p>
                    <span className="mt-1 block text-center text-xs">
                      $80/mo
                    </span>
                  </label>
                  <label className="">
                    <input
                      type="radio"
                      name="subscription"
                      value="8 Months"
                      className="peer sr-only"
                      checked
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      8 Months
                    </p>
                    <span className="mt-1 block text-center text-xs">
                      $60/mo
                    </span>
                  </label>
                  <label className="">
                    <input
                      type="radio"
                      name="subscription"
                      value="12 Months"
                      className="peer sr-only"
                    />
                    <p className="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                      12 Months
                    </p>
                    <span className="mt-1 block text-center text-xs">
                      $40/mo
                    </span>
                  </label>
                </div> */}

                {data && data.hide_price === true ? (
                  <div className="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                    <div className="flex items-end">
                      <h1 className="text-3xl font-bold mr-4">
                        {data.discount_price === 0
                          ? `₮ ${data.regular_price}`
                          : `₮ ${data.discount_price}`}
                      </h1>
                      <h1 className="text-sm text-slate-900 line-through">
                        {data.discount_price === 0
                          ? ""
                          : `₮ ${data.regular_price}`}
                      </h1>
                    </div>

                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border-2 border-transparent bg-template-color bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow hover:bg-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="shrink-0 mr-3 h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                        />
                      </svg>
                    </button>
                  </div>
                ) : null}

                {/* <ul className="mt-8 space-y-2">
                  <li className="flex items-center text-left text-sm font-medium text-gray-600">
                    <svg
                      className="mr-2 block h-5 w-5 align-middle text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        className=""
                      ></path>
                    </svg>
                    Free shipping worldwide
                  </li>

                  <li className="flex items-center text-left text-sm font-medium text-gray-600">
                    <svg
                      className="mr-2 block h-5 w-5 align-middle text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                        className=""
                      ></path>
                    </svg>
                    Cancel Anytime
                  </li>
                </ul> */}
              </div>

              <div className="lg:col-span-12">
                <div className="border-b border-gray-300">
                  <nav className="flex gap-4">
                    <Link
                      href="#"
                      title=""
                      className="border-b-2 border-gray-900 py-4 text-sm font-medium text-gray-900 hover:border-gray-400 hover:text-gray-800"
                    >
                      Бүтээгдэхүүн тайлбар
                    </Link>
                  </nav>
                </div>

                <div className="mt-8 flow-root sm:mt-12">
                  {content && content}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : loading === true ? (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          {/* <Page404 /> */}
          <div className=" rounded-md items-center  h-12 w-12 border-4 border-t-4 border-template-color animate-spin absolute"></div>
        </div>
      ) : (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          <Page404 />
        </div>
      )}
    </div>
  );
}
