import { useEffect, useState, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { Loading, Spacer } from "@nextui-org/react";

import API from "../../utils/axios";
import URL from "../../utils/url";
import noImage from "../../image/noImage.jpg";
import Page404 from "../404";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Best Rating", href: "#", current: false },
  { name: "Newest", href: "#", current: false },
  { name: "Price: Low to High", href: "#", current: false },
  { name: "Price: High to Low", href: "#", current: false },
];
const subCategories = [
  { name: "Totes", href: "#" },
  { name: "Backpacks", href: "#" },
  { name: "Travel Bags", href: "#" },
  { name: "Hip Bags", href: "#" },
  { name: "Laptop Sleeves", href: "#" },
];
const filters = [
  {
    id: "category",
    name: "Category",
    options: [
      { value: "new-arrivals", label: "New Arrivals", checked: false },
      { value: "sale", label: "Sale", checked: false },
      { value: "travel", label: "Travel", checked: true },
      { value: "organization", label: "Organization", checked: false },
      { value: "accessories", label: "Accessories", checked: false },
    ],
  },
  // {
  //   id: "size",
  //   name: "Size",
  //   options: [
  //     { value: "2l", label: "2L", checked: false },
  //     { value: "6l", label: "6L", checked: false },
  //     { value: "12l", label: "12L", checked: false },
  //     { value: "18l", label: "18L", checked: false },
  //     { value: "20l", label: "20L", checked: false },
  //     { value: "40l", label: "40L", checked: true },
  //   ],
  // },
];

export default function CategoryFilter() {
  const { id } = useParams();

  const [_product, _setProduct] = useState([]);
  const [_productAll, _setProductAll] = useState([]);
  const [_category, _setCategory] = useState([]);
  const [_activeCategory, _seActiveCategory] = useState();
  const [_images, _setImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState("");
  const [content, setContent] = useState([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      let category = await get("/category/list?active=1");

      let product = await get("/product/list?active=1");
      let images = await get("/product/image");

      if (
        category.status === "success" &&
        product.status === "success" &&
        images.status === "success"
      ) {
        _setCategory(category.result);
        _setImage(images.result);
        _setProductAll(product.result);
        imageToProduct(id, product.result, images.result);
      } else {
        setLoading(false);
      }
    };
    getData();

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const imageToProduct = (catId, data, image) => {
    _setProduct([]);
    if (data.length > 0) {
      _seActiveCategory(parseInt(catId));
      let findProduct = data.filter(
        (item) => item.category_id === parseInt(catId)
      );
      if (findProduct.length > 0) {
        findProduct.map((productItem, index) => {
          if (image.length > 0) {
            let findImage = image.filter(
              (item) => item.product_id === productItem.id
            );
            if (findImage.length > 0) {
              findProduct[index].images = findImage;
            } else {
              findProduct[index].images = [];
            }
          }
        });
        _setProduct(findProduct);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div>
      {_category && _category.length > 0 ? (
        <div className="w-screen max-w-[1400px] mx-auto  items-center   bg-white">
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        Filters
                      </h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="sr-only">Categories</h3>
                      <ul
                        role="list"
                        className="px-2 py-3 font-medium text-gray-900"
                      >
                        {subCategories.map((category) => (
                          <li key={category.name}>
                            <Link
                              href={category.href}
                              className="block px-2 py-3"
                            >
                              {category.name}
                            </Link>
                          </li>
                        ))}
                      </ul>

                      {filters.map((section) => (
                        <Disclosure
                          as="div"
                          key={section.id}
                          className="border-t border-gray-200 px-4 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {section.name}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={option.value}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        defaultChecked={option.checked}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto max-full px-4 sm:px-6 lg:px-8">
            <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-10">
              {/* <h1 className="text-4xl font-bold tracking-tight text-template-color">
                Смарт систем
              </h1> */}

              {/* <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                href={option.href}
                                className={classNames(
                                  option.current
                                    ? "font-medium text-gray-900"
                                    : "text-gray-500",
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <button
                  type="button"
                  className="-m-2 ml-5 p-2 text-gray-400 hover:text-gray-500 sm:ml-7"
                >
                  <span className="sr-only">View grid</span>
                  <Squares2X2Icon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div> */}
            </div>

            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <form className="  lg:block">
                  <h3 className="sr-only">Categories</h3>
                  {/* <ul
                    role="list"
                    className="space-y-4 border-b border-gray-200 pb-6 text-sm font-medium text-gray-900"
                  >
                    {subCategories.map((category) => (
                      <li key={category.name}>
                        <a href={category.href}>{category.name}</a>
                      </li>
                    ))}
                  </ul> */}

                  <Disclosure
                    as="div"
                    // key={section.id}
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Бүх ангилал
                            </span>
                            <span className="ml-6 flex items-center">
                              {/* {open ? (
                                  <MinusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <PlusIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                )} */}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <div className="pt-6">
                          <div className="space-y-4">
                            {/* {section.options.map((option, optionIdx) => ( */}
                            {_category && _category.length > 0
                              ? _category.map((item, index) => (
                                  <div
                                    key={index}
                                    // key={option.value}
                                    className="flex items-center"
                                  >
                                    {/* <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              type="checkbox"
                              defaultChecked={option.checked}
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            /> */}
                                    <label
                                      // htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className={`ml-3 text-sm   p-1 pr-3 pl-3 rounded-lg ${
                                        _activeCategory === item.id
                                          ? "bg-template-color text-neutral-100"
                                          : "text-gray-600"
                                      } hover:bg-template-color hover:text-neutral-100`}
                                      onClick={() =>
                                        imageToProduct(
                                          item.id,
                                          _productAll,
                                          _images
                                        )
                                      }
                                    >
                                      {item.name}
                                    </label>
                                  </div>
                                ))
                              : null}

                            {/* ))} */}
                          </div>
                        </div>
                      </>
                    )}
                  </Disclosure>
                </form>

                {/* Product grid */}
                <div className="lg:col-span-3">
                  <main className="my-8">
                    <div className="container mx-auto px-6">
                      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
                        {_product && _product.length > 0
                          ? _product.map((item, index) => (
                              <Link to={`/product/${item.id}`} key={index}>
                                <div className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden">
                                  <div
                                    className="flex items-end justify-end h-56 w-full bg-cover"
                                    style={{
                                      backgroundImage: `url(
                                ${
                                  item.images.length > 0
                                    ? `${URL.substring(0, URL.length - 4)}/${
                                        item.images[0].image_path
                                      }`
                                    : noImage
                                }
                              )`,
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  <div className="px-5 py-3">
                                    <h3 className="text-gray-700 uppercase h-20">
                                      {item.name}
                                    </h3>

                                    {item.hide_price === true ? (
                                      <span className="text-gray-500 mt-2 ">
                                        <span className="text-lg font-bold text-slate-900 mr-2">
                                          {item.discount_price === 0
                                            ? `₮ ${item.regular_price}`
                                            : `₮ ${item.discount_price}`}
                                        </span>
                                        <span className="text-sm text-slate-900 line-through">
                                          {item.discount_price === 0
                                            ? ""
                                            : `₮ ${item.regular_price}`}
                                        </span>
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </Link>
                            ))
                          : null}
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : loading === true ? (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          {/* <Page404 /> */}
          <div className=" rounded-md items-center  h-12 w-12 border-4 border-t-4 border-template-color animate-spin absolute"></div>
        </div>
      ) : (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          <Page404 />
        </div>
      )}
    </div>
  );
}
