import React from "react";
import { Link, Routes, Route, useNavigate } from "react-router-dom";

const Page404 = () => {
  return (
    <main className="h-screen w-full flex flex-col justify-center items-center bg-[#1A2238]">
      <h1 className="text-9xl font-extrabold text-white tracking-widest">
        404
      </h1>
      <div className="bg-template-color px-2 text-sm rounded rotate-12 absolute">
        Page Not Found
      </div>
      <button className="mt-5">
        <Link className="relative inline-block text-sm font-medium text-template-color group active:text-template-color focus:outline-none focus:ring">
          <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-template-color group-hover:translate-y-0 group-hover:translate-x-0"></span>

          <span className="relative block px-8 py-3 bg-[#1A2238] border border-current">
            <Link to="/">Буцах</Link>
          </span>
        </Link>
      </button>
    </main>
  );
};
export default Page404;
