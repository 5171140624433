"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { links } from "../../utils/data";
import clsx from "clsx";
// import Image from "next/image";
import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import Select, { components } from "react-select";

import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineCompass,
  AiOutlineDeliveredProcedure,
  AiOutlineUser,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import API from "../../utils/axios";
import URL from "../../utils/url";
import noImage from "../../image/noImage.jpg";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const [menuIcon, setIcon] = useState(false);
  const [data, setData] = useState();
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();
  const [productFilter, setProductFilter] = useState();
  const [search, setSearch] = useState();
  const [active, setActive] = useState();

  const navigate = useNavigate();

  const links = [
    {
      id: 1,
      name: "Нүүр",
      hash: "/#home",
    },
    {
      id: 2,
      name: "Ангилал",
      hash: "/#category",
    },
    {
      id: 3,
      name: "Бүтээгдэхүүн",
      hash: "/#product",
    },
    {
      id: 4,
      name: "Танилцуулга",
      hash: "/#about",
    },
    {
      id: 5,
      name: "Холбоо барих",
      hash: "/#contact",
    },
  ];

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      let pages = await get("/setting/information");
      if (pages.status === "success") {
        if (pages.result.length > 0) {
          setData(pages.result[0]);
        }
      }
      let categories = await get("/category/list?active=1");
      if (categories.status === "success") {
        if (categories.result.length > 0) {
          setCategory(categories.result);
        }
      }

      let products = await get("/product/list?active=1");
      if (products.status === "success") {
        if (products.result.length > 0) {
          setProduct(products.result);
        }
      }
    };
    getData();
  }, []);

  const handleSmallerScreenNavigation = () => {
    setIcon(!menuIcon);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const onSearch = (e) => {
    let updatedList = product.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      );
    });
    // Trigger render with updated values
    setProductFilter(updatedList);
  };

  const styles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#63a9bd" : isSelected ? "#63a9bd" : "",
      };
    },
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <button
          className="bg-template-color relative z-[2] flex items-center rounded-r px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-template-color hover:shadow-lg focus:bg-template-color focus:shadow-lg focus:outline-none focus:ring-0 active:bg-template-color active:shadow-lg"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
          >
            <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" />
          </svg>
        </button>
      </components.DropdownIndicator>
    );
  };

  return (
    <header className="bg-template-color w-full ease-in duration-300 fixed top-0 left-0 z-10">
      <div className="hidden md:block  bg-neutral-100 w-full visible">
        <div className="max-w-[1400px] text-template-color mx-auto h-[50px] grid content-center">
          <div className="flex justify-between">
            <div className="grid-cols-2 flex flex-row">
              <div className="flex flex-row space-x-2 text-2xl">
                {/* <Link to={"facebook.com"}> */}
                <AiFillFacebook
                  onClick={() =>
                    openInNewTab(
                      `${data && data.facebook !== null ? data.facebook : "#"}`
                    )
                  }
                />
                {/* </Link> */}
                <AiFillInstagram
                  onClick={() =>
                    openInNewTab(
                      `${
                        data && data.instagram !== null ? data.instagram : "#"
                      }`
                    )
                  }
                />
              </div>
              <div className="grid-cols-3 flex flex-row space-x-4">
                <div className="flex flex-row space-x-2 text-2xl">
                  <AiOutlinePhone />
                  <span className="text-base">
                    {data && data.phone_number !== null
                      ? data.phone_number
                      : ""}
                  </span>
                </div>
                <div className="flex flex-row space-x-2 text-2xl">
                  <AiOutlineMail />
                  <span className="text-base">
                    {data && data.email !== null ? data.email : ""}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid-cols-3 flex flex-row space-x-4">
              <div className="flex flex-row space-x-2 text-2xl">
                <AiOutlineCompass />
                <span className="text-base">
                  {data && data.city !== null ? data.city : ""}
                </span>
              </div>
              <div className="flex flex-row space-x-2 text-2xl">
                <AiOutlinePhone />
                <span className="text-base">
                  {data && data.phone_number !== null ? data.phone_number : ""}
                </span>
              </div>
              {/* <div className="flex flex-row space-x-2 text-2xl">
                <AiOutlineUser /> <span className="text-base">User</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:block bg-neutral-100 w-full">
        <div className="max-w-[1400px] mx-auto h-[100px] grid content-center">
          <div className=" flex flex-wrap items-center">
            {/* <Image
              src="/images/logo/logo.jpg"
              width={130}
              height={20}
              alt="Logo"
            /> */}
            <div class="w-1/5">
              {data && data.website_logo !== null ? (
                <Link to={"/"}>
                  <img
                    width={130}
                    height={20}
                    src={`${URL.substring(0, URL.length - 4)}/${
                      data.website_logo
                    }`}
                  />
                </Link>
              ) : (
                <Link to={"/"}>
                  <img width={70} height={20} src={noImage} />
                </Link>
              )}
            </div>
            <div class="w-3/5">
              <div className="grid content-center">
                <div className="flex w-full flex-wrap items-stretch">
                  {/* <input
                  value={search}
                  onChange={(e) => onSearch(e)}
                  type="search"
                  className="-mr-0.5 block w-92 min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-template-color focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-template-color"
                  placeholder="Хайлт"
                  aria-label="Search"
                  aria-describedby="button-addon1"
                />

                <button
                  className="bg-template-color relative z-[2] flex items-center rounded-r px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-template-color hover:shadow-lg focus:bg-template-color focus:shadow-lg focus:outline-none focus:ring-0 active:bg-template-color active:shadow-lg"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" />
                  </svg>
                </button> */}
                  <Select
                    value={search}
                    options={
                      product &&
                      product.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    }
                    placeholder="Хайл хийх"
                    components={{ DropdownIndicator }}
                    className=" block w-92 min-w-0 flex-auto    bg-transparent bg-clip-padding  py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-template-color focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-template-color"
                    myFontSize="20px"
                    styles={styles}
                    onChange={(value) => {
                      navigate(`/product/${value.value}`, { replace: true });
                      window.location.reload();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid content-center justify-end">
              {/* <Button
                variant="bordered"
                className="w-32 border-template-color text-template-color"
                startContent={<AiOutlineShoppingCart size={25} />}
              >
                Cart
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <nav className="max-w-[1400px] mx-auto h-[100px] md:h-[50px] flex justify-between items-center p-4 md:p-0">
        <div className="flex md:hidden">
          {/* <Image
            src="/images/logo/logo.jpg"
            width={130}
            height={20}
            alt="Logo"
          /> */}

          {data && data.website_logo !== null ? (
            <Link to={"/"}>
              <img
                width={130}
                height={20}
                src={`${URL.substring(0, URL.length - 4)}/${data.website_logo}`}
              />
            </Link>
          ) : (
            <Link to={"/"}>
              <img width={70} height={20} src={noImage} />
            </Link>
          )}

          {/* <img src={`${URL}/static/${item.url}`} className="w-full" /> */}
        </div>
        <Dropdown>
          <DropdownTrigger>
            <Button
              className="invisible md:visible uppercase bg-neutral-100 font-bold	text-template-color rounded-none"
              startContent={<AiOutlineMenu size={25} />}
            >
              Бүх ангилал
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Dynamic Actions">
            {category && category.length > 0
              ? category.map((item, index) => {
                  return (
                    <DropdownItem key={index} color="secondary">
                      <Link to={`/category/${item.id}`}> {item.name}</Link>
                    </DropdownItem>
                  );
                })
              : null}
          </DropdownMenu>
        </Dropdown>

        <ul className="hidden md:flex uppercase font-semibold text-1xl lg:text-[16px] text-neutral-100">
          {links.map((link) => (
            <motion.li
              className="h-4/4  m-0.5 flex items-center justify-center relative"
              key={link.hash}
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              <a
                className={clsx(
                  "flex w-full items-center justify-center px-3 py-3 hover:text-white-100 transition dark:text-gray-500 dark:hover:text-neutral-100 hover:bg-neutral-100 hover:text-template-color",
                  {
                    "text-template-color bg-neutral-100 dark:text-neutral-100":
                      active === link.id,
                  }
                )}
                href={link.hash}
                onClick={() => {
                  setActive(link.id);
                }}
              >
                {link.name}
              </a>
            </motion.li>
          ))}
        </ul>
        <div onClick={handleSmallerScreenNavigation} className="flex md:hidden">
          {menuIcon ? (
            <AiOutlineClose size={25} className="text-neutral-100" />
          ) : (
            <AiOutlineMenu size={25} className="text-neutral-100" />
          )}
        </div>

        <div
          className={
            menuIcon
              ? "md:hidden absolute top-[100px] ring-0 bottom-0 left-0 flex justify-center items-center w-full h-screen bg-slate-800 text-white ease-in duration-300"
              : "md:hidden absolute top-[100px] ring-0 left-[-100%] flex justify-center items-center w-full h-screen bg-slate-800 text-white ease-in duration-300"
          }
        >
          <div className="w-full">
            <ul className="uppercase font-bold text-2xl">
              {links.map((link, index) => (
                <motion.li
                  className=" h-4/4  m-0.5 flex items-center justify-center relative"
                  key={index}
                  initial={{ y: -100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  <a
                    className={clsx(
                      "flex w-full items-center justify-center px-3 py-3 hover:text-gray-950 transition dark:text-gray-500 dark:hover:text-gray-300",
                      {
                        "text-template-color dark:text-gray-200":
                          active === link.id,
                      }
                    )}
                    href={link.hash}
                    onClick={() => {
                      setActive(link.id);
                      handleSmallerScreenNavigation();
                    }}
                  >
                    {link.name}

                    {/* {link.name === activeSection && (
                      <motion.span
                        className="bg-gray-100  absolute inset-0 -z-10 dark:bg-gray-800"
                        layoutId="activeSection"
                        transition={{
                          type: "spring",
                          stiffness: 380,
                          damping: 30,
                        }}
                      ></motion.span>
                    )} */}
                  </a>
                </motion.li>
              ))}
            </ul>
            {/* <div className="flex flex-col justify-between items-center mt-16">
              <Link href="" onClick={handleSmallerScreenNavigation}>
                <button className="bg-[#CEFF00] text-slate-800 rounded-full uppercase font-bold py-3 w-[250px] mb-5">
                  Login
                </button>
              </Link>
              <Link href="" onClick={handleSmallerScreenNavigation}>
                <button className="border-2 border-[#CEFF00] text-white rounded-full uppercase font-bold py-3 w-[250px] mb-5">
                  Register
                </button>
              </Link>
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  );
}
