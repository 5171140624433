import { useState } from "react";
import { Button } from "@nextui-org/react";

export default function ContactForm() {
  const [isSubmitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        body: JSON.stringify({
          name,
          email,
          message,
        }),
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        setSubmitted(true);
      }
    } catch (err) {
      console.error("Err", err);
    }
  };
  return isSubmitted ? (
    <div>
      <h1
        className="text-center font-semibold text-3xl
      "
      >
        Thank you for your message!
      </h1>
    </div>
  ) : (
    <form onSubmit={onSubmit} className="flex flex-col gap-4 text-left">
      <div className="">
        <label className="label font-semibold">
          <span className="label-text">Нэр</span>
        </label>
        <input
          className="border rounded-lg p-2 input w-full input-bordered input-primary"
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder="Нэр"
        />
      </div>
      <div>
        <label className="label font-semibold">
          <span className="label-text">Имэйл</span>
        </label>
        <input
          className="border rounded-lg p-2 input w-full input-bordered input-primary"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="name@example.com"
        />
      </div>
      <div>
        <label className="label font-semibold">
          <span className="label-text">Мессэж</span>
        </label>
        <textarea
          className="border rounded-lg p-2 textarea w-full textarea-primary"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <Button
        variant="bordered"
        className="bg-template-color border-template-color text-neutral-100"
      >
        Илгээх
      </Button>
    </form>
  );
}
