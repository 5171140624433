// "use client";
// import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Virtual, Navigation, Pagination } from "swiper/modules";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
// import "swiper/css";
// import "swiper/css/navigation";
// import API from "../../../utils/axios";
// import URL from "../../../utils/url";
// import noImage from "../../../image/noImage.jpg";
import Image from "rc-image";

// export default function CategorySection() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);

//   const get = (getLink) => {
//     return API.getData(getLink)
//       .then(async (response) => {
//         let result = await response;
//         return result;
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   useEffect(() => {
//     setLoading(true);
//     const getData = async () => {
//       let pages = await get("/category/list?active=1");
//       if (pages.status === "success") {
//         setData(pages.result);
//       }
//     };
//     getData();
//     setLoading(false);
//   }, []);

//   const [swiperRef, setSwiperRef] = useState(null);

//   return (
//     <>
//       {loading === true ? (
//         <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
//           {/* <Page404 /> */}
//           <div className=" rounded-md items-center  h-12 w-12 border-4 border-t-4 border-template-color animate-spin absolute"></div>
//         </div>
//       ) : (
//         <motion.section
//           className="w-full mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-100"
//           initial={{ opacity: 0, y: 100 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: 0.175 }}
//           id="category"
//         >
//           <div className="w-full grid place-items-center">
//             <p className="text-[40px] capitalize font-bold py-8">Ангилал</p>
//             <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
//               {data && data.length > 0
//                 ? data.map((image, index) => (
//                     <div className="card" key={`section2-${index}`}>
//                       <Link to={`/category/${image.id}`}>
//                         <div className="p-5 flex flex-col place-items-center">
//                           <div className="rounded-xl overflow-hidden">
//                             {/* <img
//                           className="flex h-32 md:h-48 w-full items-center justify-center"
//                           src={`${URL}/static/${image.image_path}`}
//                         /> */}
//                             <Image
//                               className="flex h-32 md:h-48 w-full items-center justify-center transition duration-300 ease-in-out hover:scale-110"
//                               src={`${URL}/static/${image.image_path} `}
//                               loading="lazy"
//                             />
//                           </div>
//                           <p className="text-black-500  text-lg mt-3">
//                             {image.name}
//                           </p>
//                         </div>
//                       </Link>
//                     </div>
//                   ))
//                 : null}
//             </div>
//           </div>
//         </motion.section>
//       )}
//     </>
//   );
// }

import React, { Component } from "react";
import API from "../../../utils/axios";
import URL from "../../../utils/url";

class CategorySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: null,
    };
  }

  get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async componentDidMount() {
    try {
      API.getData("/category/list?active=1")
        .then(async (response) => {
          let data = await response;
          if (data.status === "success") {
            let getResult = data.result;
            // setData(pages.result);
            this.setState({ data: getResult, loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { data, loading, error } = this.state;

    if (loading) {
      return (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          {/* <Page404 /> */}
          <div className=" rounded-md items-center  h-12 w-12 border-4 border-t-4 border-template-color animate-spin absolute"></div>
        </div>
      );
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <>
        <motion.section
          className="w-full mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-100"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.175 }}
          id="category"
        >
          <div className="w-full grid place-items-center">
            <p className="text-[40px] capitalize font-bold py-8">Ангилал</p>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {data && data.length > 0
                ? data.map((image, index) => (
                    <div className="card" key={`section2-${index}`}>
                      <Link to={`/category/${image.id}`}>
                        <div className="p-5 flex flex-col place-items-center">
                          <div className="rounded-xl overflow-hidden">
                            {/* <img
                          className="flex h-32 md:h-48 w-full items-center justify-center"
                          src={`${URL}/static/${image.image_path}`}
                        /> */}
                            <Image
                              className="flex h-32 md:h-48 w-full items-center justify-center transition duration-300 ease-in-out hover:scale-110"
                              src={`${URL.substring(0, URL.length - 4)}/${
                                image.image_path
                              } `}
                              loading="lazy"
                            />
                          </div>
                          <p className="text-black-500  text-lg mt-3">
                            {image.name}
                          </p>
                        </div>
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </motion.section>
      </>
    );
  }
}

export default CategorySection;
