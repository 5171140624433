import CategorySection from "./CategorySection";
import HomeSection from "./HomeSection";
import ProductSection from "./ProductSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";

function HomePage() {
  return (
    <div>
      <HomeSection />
      <CategorySection />
      <ProductSection />
      <AboutSection />
      <ContactSection />
    </div>
  );
}

export default HomePage;
