"use client";
import React, { Component, useEffect, useState } from "react";

import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Virtual, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import API from "../../../utils/axios";
import URL from "../../../utils/url";
import Image from "rc-image";

// export default function HomeSection() {
//   const [image, setImage] = useState([]);

//   const get = (getLink) => {
//     return API.getData(getLink)
//       .then(async (response) => {
//         let result = await response;
//         return result;
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   useEffect(() => {
//     const getData = async () => {
//       let pages = await get("/setting/page?type=home");
//       if (pages.status === "success") {
//         let imageArray = JSON.parse(pages.result[0].image_path);
//         setImage(imageArray.length > 0 ? imageArray : []);
//       }
//     };
//     getData();
//   }, []);

//   return (
//     <motion.section
//       className="w-full h-[40%]  mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-28"
//       initial={{ opacity: 0, y: 100 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ delay: 0.175 }}
//       id="home"
//     >
//       {image && image.length > 0 ? (
//         <Swiper
//           autoplay={{
//             delay: 2500,
//             disableOnInteraction: false,
//           }}
//           rewind={false}
//           navigation
//           modules={[Autoplay, Navigation, Pagination]}
//           onSwiper={(swiper) => console.log(swiper)}
//           className="w-full"
//         >
//           {image.map((image, index) => (
//             <SwiperSlide key={index}>
//               <div className="items-center justify-center">
//                 {/* <img
//                   className="w-full h-[40%] items-center justify-center"
//                   src={`${URL}/static/${image.url}`}
//                 /> */}
//                 <Image
//                   className="w-full h-[40%] items-center justify-center"
//                   src={`${URL}/static/${image.url}`}
//                 />
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       ) : null}
//     </motion.section>
//   );
// }

class HomeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: null,
    };
  }

  async componentDidMount() {
    try {
      API.getData("/setting/page?type=home")
        .then(async (response) => {
          let data = await response;

          if (data.status === "success") {
            let imageArray = JSON.parse(data.result[0].image_path);
            // setData(pages.result);
            this.setState({
              data: imageArray.length > 0 ? imageArray : [],
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const { data, loading, error } = this.state;

    if (loading) {
      return (
        <div className="w-screen h-screen  flex justify-center  max-w-[1400px]   items-center leading-8  scroll-mt-28">
          {/* <Page404 /> */}
          <div className=" rounded-md items-center  h-12 w-12 border-4 border-t-4 border-template-color animate-spin absolute"></div>
        </div>
      );
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <>
        <motion.section
          className="w-full h-[600]  mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-28"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.175 }}
          id="home"
        >
          {data && data.length > 0 ? (
            <Swiper
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              rewind={false}
              navigation
              modules={[Autoplay, Navigation, Pagination]}
              onSwiper={(swiper) => console.log(swiper)}
              className="w-full"
            >
              {data.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="items-center justify-center">
                    {/* <img
                  className="w-full h-[40%] items-center justify-center"
                  src={`${URL}/static/${image.url}`}
                /> */}
                    <Image
                      className="w-full h-[40%] items-center justify-center"
                      src={`${URL.substring(0, URL.length - 4)}/${image.url}`}
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </motion.section>
      </>
    );
  }
}

export default HomeSection;
