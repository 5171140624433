"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  AiFillCompass,
  AiOutlineCompass,
  AiOutlineFieldTime,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineMediumWorkmark,
  AiOutlinePhone,
} from "react-icons/ai";
import ContactForm from "../../../components/contactForm";
import API from "../../../utils/axios";

export default function ContactSection() {
  //   const { ref } = useSectionInView("Contact");

  const [data, setData] = useState();

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      let pages = await get("/setting/information");
      if (pages.status === "success") {
        if (pages.result.length > 0) {
          setData(pages.result[0]);
        }
      }
    };
    getData();
  }, []);

  return (
    <motion.section
      //   ref={ref}
      className="mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-28"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.175 }}
      id="contact"
    >
      <div className="w-full grid place-items-center">
        <p className="text-[40px] capitalize font-bold py-8">Холбоо барих</p>

        <div className="flex justify-center items-center">
          <div className="flex-1 p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-1 gap-4 grid-flow-dense">
              <div className="p-4 pr-6 bg-white border-1-8 border-transparent rounded-md shadow-md space-y-2">
                <div className="flex">
                  <div className="w-1/6 justify-center content-center grid">
                    <AiOutlineCompass size={35} />
                  </div>
                  <div className="w-5/6 items-start content-center grid">
                    {data && data.address !== null ? data.address : ""}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/6 justify-center content-center grid">
                    <AiOutlinePhone size={35} />
                  </div>
                  <div className="w-5/6 justify-start content-center grid">
                    {data && data.phone_number !== null
                      ? data.phone_number
                      : ""}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/6 justify-center content-center grid">
                    <AiOutlineMail size={35} />
                  </div>
                  <div className="w-5/6 justify-start content-center grid">
                    {data && data.email !== null ? data.email : ""}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-1/6 justify-center content-center grid">
                    <AiOutlineFieldTime size={35} />
                  </div>
                  <div className=" w-5/6 justify-start content-center grid">
                    {data && data.work_time !== null ? data.work_time : ""}
                  </div>
                </div>
              </div>
              <div className="p-4 pr-6 bg-white border-1-8 border-transparent rounded-md shadow-md space-y-2">
                <ContactForm />
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d12357.3935722133!2d106.90254598632704!3d47.9181238094891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2smn!4v1697897558235!5m2!1sen!2smn"
              width="600"
              height="450"
              loading="lazy"
              className="w-full pt-4"
            ></iframe>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
