import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

function Layout() {
  return (
    <div className="bg-gradient-to-t">
      <Header />
      <main className="max-w-[1400px] mx-auto flex flex-col mt-[50px] md:mt-[200px]">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
