"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Virtual, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import API from "../../../utils/axios";
import URL from "../../../utils/url";
import noImage from "../../../image/noImage.jpg";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import Image from "rc-image";

export default function ProductSection() {
  //   const { ref } = useSectionInView("Product");
  const [swiperRef, setSwiperRef] = useState(null);
  const [data, setData] = useState([]);

  const get = (getLink) => {
    return API.getData(getLink)
      .then(async (response) => {
        let result = await response;
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getData = async () => {
      let pages = await get("/product/list?active=1");
      let images = await get("/product/image");

      if (pages.status === "success" && images.status === "success") {
        if (pages.result.length > 0) {
          pages.result.map((product) => {
            if (images.result.length > 0) {
              let findImage = images.result.filter(
                (item) => item.product_id === product.id
              );
              if (findImage.length > 0) {
                product.images = findImage;
              }
            }
          });
        }
        setData(pages.result);
      }
    };
    getData();
  }, []);

  return (
    <motion.section
      //   ref={ref}
      className="w-full mb-28 max-w-[1400px] text-center leading-8 sm:mb-40 scroll-mt-28"
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.175 }}
      id="product"
    >
      <div className="place-items-center">
        {data && data.length > 0 ? (
          <>
            <p className="text-[40px] capitalize font-bold py-8">
              Бүтээгдэхүүн
            </p>
            <Swiper
              modules={[Virtual, Navigation, Pagination]}
              onSwiper={setSwiperRef}
              slidesPerView={4}
              spaceBetween={25}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              navigation={true}
              virtual
            >
              {data.map((product, index) => (
                <SwiperSlide key={`section3-${index}`}>
                  <Link to={`/product/${product.id}`}>
                    <div className="card">
                      <div className="p-5 flex flex-col place-items-center">
                        <div className="bg-gray-100 grid h-64 w-84 rounded-xl overflow-hidden content-center">
                          {product.images && product.images.length > 0 ? (
                            // <img
                            //   className="object-cover h-64 w-96"
                            //   src={`${URL}/static/${product.images[0].image_path}`}
                            //   loading="lazy"
                            // />

                            <Image
                              key={index}
                              className="object-cover w-56 m-4 transition duration-300 ease-in-out hover:scale-110"
                              src={`${URL.substring(0, URL.length - 4)}/${
                                product.images[0].image_path
                              }`}
                            />
                          ) : (
                            <img
                              className="object-cover h-64 w-96"
                              src={noImage}
                            />
                          )}
                        </div>
                        {/* <p className="text-black-500 text-lg mt-3">
                        {product.name}
                      </p> */}
                        <Link href="#" className="mt-2 flex items-center h-20">
                          <h5 className="text-md tracking-tight text-slate-900  ">
                            {product.name}
                          </h5>
                        </Link>
                        <div className="mt-2 mb-5 flex items-center justify-between">
                          {product.hide_price && product.hide_price === true ? (
                            <p>
                              <span className="text-xl font-bold text-slate-900 mr-2">
                                {product.discount_price === 0
                                  ? `₮ ${product.regular_price}`
                                  : `₮ ${product.discount_price}`}
                              </span>
                              <span className="text-sm text-slate-900 line-through">
                                {product.discount_price === 0
                                  ? ""
                                  : `₮ ${product.regular_price}`}
                              </span>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        ) : null}
      </div>
    </motion.section>
  );
}
